import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import '../../../assets/css/footer.css';
import {Event} from '../../GAClickEvent';
import facebookIcon from '../../../assets/images/header-footer/social/facebook-icon-2.svg';
import twitterIcon from '../../../assets/images/header-footer/social/twitter-icon-2.svg';
import linkedInIcon from '../../../assets/images/header-footer/social/linkedin-icon-2.svg';
import emailIcon from '../../../assets/images/header-footer/social/email-icon-2.svg';

const $ = typeof window !== `undefined` ? require('jquery') : null;

const backToTop = e => {
  e.preventDefault();
  window.scrollTo({top: 0, behavior: 'smooth'});
};

const Footer = ({
  showPortfolioSection,
  showContactSection,
  backgroundColorTopSection,
  backgroundColorBottomSection,
}) => {
  const [isGamesPage, setIsGamesPage] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsGamesPage(window.location.pathname === '/games/'); // Check the current path
    }
  }, []);

  return (
    <footer className="footer">
      {!isGamesPage && showContactSection ? (
        <div id="signup-section" className="py-4 signup-section">
          <div className="footer-container">
            <div className="form-row center-property">
              <div className="signup-text col-12 mb-4 mb-md-0 col-md-8 ">
                <div className="heading latoBlack">
                  What business outcomes are you looking to hit?
                </div>
              </div>
              <div className="col-md-4">
                <Link
                  className="btn btn-block btn-success-foot signup-btn"
                  onClick={() =>
                    Event("Let's Get Started", "Let's talk Started button is clicked", 'footer')
                  }
                  to="/contact"
                >
                  Let's talk
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div
        className="address-section"
        style={{backgroundColor: isGamesPage ? '#222E68' : backgroundColorTopSection}}
      >
        <div className="footer-container d-flex text-align-center col-12 w-100">
          <div className="justify-content-center text-align-center p-0 col-12 w-100">
            <div className="col-12 mt-5 mb-5 p-0">
              <div className="d-flex flex-row flex-wrap justify-content-between">
                <div className="col-lg-4 col-md-6 mb-3 p-0">
                  <div className="latoBold heading text-white">Location</div>
                  <ul>
                    <li className="city text-decoration-none">
                      <address>
                        <span className="latoBold ">USA</span>
                      </address>
                    </li>
                    <li className="city text-decoration-none">
                      <address>
                        <span className="latoBold">Canada</span>
                      </address>
                    </li>
                    <li className="city text-decoration-none">
                      <address>
                        <span className="latoBold">UAE</span>
                      </address>
                    </li>
                    <li className="city text-decoration-none">
                      <address>
                        <span className="latoBold">Norway</span>
                      </address>
                    </li>
                    <li className="city text-decoration-none">
                      <address>
                        <span className="latoBold">Ukraine</span>
                      </address>
                    </li>
                    <li className="city text-decoration-none">
                      <address>
                        <span className="latoBold">Pakistan</span>
                      </address>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-4 mb-3 p-0 footer_address social-icons">
                  <div className="latoBold heading text-white">Connect</div>

                  <ul className="mb-0">
                    <li className="contact">Let's have a conversation.</li>
                    <li className="contact">
                      <a
                        onClick={() =>
                          Event(
                            'Dial Phone Number',
                            'Dial Phone Number button is clicked',
                            'Header'
                          )
                        }
                        className="nav-link nav-link-text pl-0"
                        style={{color: '#A0AABC'}}
                        href="tel:+14152340744"
                      >
                        <i className="fa fa-rotate-90 fa-phone fa-xs"></i>
                        &nbsp; +1 (415) 234-0744
                      </a>
                    </li>
                  </ul>
                  <div className="footer__social-links-container">
                    <a href="https://facebook.com/Tintash" target="_blank">
                      <img src={facebookIcon} alt="Tintash facebook" />
                    </a>

                    <a href="https://x.com/TintashApps" target="_blank">
                      <img src={twitterIcon} alt="Tintash twitter" />
                    </a>

                    <a href="https://www.linkedin.com/company/241494" target="_blank">
                      <img src={linkedInIcon} alt="Tintash linkedin" />
                    </a>

                    <a href="mailto:bd@tintash.com" target="_blank">
                      <img src={emailIcon} alt="Tintash Email" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 footer_address social-icons p-0">
                  <div className="latoBold heading text-white">Links</div>
                  <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                    <div className="city" style={{marginBottom: '4px'}}>
                      <Link to="/privacy" className="city">
                        <span className="latoRegular">Privacy Policy</span>&nbsp;
                      </Link>
                    </div>

                    <div className="city">
                      <Link to="/fraud-disclaimer" className="city">
                        <span className="latoRegular">Fraud Disclaimer</span>&nbsp;
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-bar clearfix"
        style={{backgroundColor: isGamesPage ? '#192352' : backgroundColorBottomSection}}
      >
        <div className="footer-container h-100 py-1">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-sm-8 col-12">
              <p className="latoBold mb-1 copy-right">
                Copyright 2024 Tintash - All Rights Reserved
              </p>
            </div>

            <div className="col-sm-4 col-12">
              <button
                type="submit"
                id="Top"
                className="latoBold text-white mb-0 back-top border-0 bg-transparent"
                onClick={backToTop}
              >
                Back To Top
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  showPortfolioSection: PropTypes.bool,
  showContactSection: PropTypes.bool,
  backgroundColorTopSection: PropTypes.string,
  backgroundColorBottomSection: PropTypes.string,
};

Footer.defaultProps = {
  showPortfolioSection: true,
  showContactSection: true,
  backgroundColorTopSection: '#282c34',
  backgroundColorBottomSection: '#1f2229',
};

export default Footer;
